var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "question-answer-form px-7 py-4" },
    [
      _c("div", { staticClass: "content-header mt-3 mb-1" }, [
        _vm._v(" " + _vm._s(!_vm.$language ? "Answers" : "উত্তর") + " "),
      ]),
      _c(
        "v-form",
        {
          ref: "answerDetailsForm",
          model: {
            value: _vm.answerFormOk,
            callback: function ($$v) {
              _vm.answerFormOk = $$v
            },
            expression: "answerFormOk",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      value: _vm.formData.word_limit,
                      label: !_vm.$language
                        ? "Character Limit"
                        : "ক্যারেক্টার লিমিট",
                      placeholder: !_vm.$language
                        ? "Enter character limit"
                        : "ক্যারেক্টার লিমিট লিখুন",
                      readonly: _vm.readonly,
                      type: "number",
                      rules: _vm.rules.word_limit,
                    },
                    on: {
                      change: (v) => (_vm.formData.word_limit = v),
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }