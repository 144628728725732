var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "px-7 py-4" },
    [
      _c(
        "v-form",
        {
          ref: "questionDetailsForm",
          attrs: { value: _vm.questionFormOk && _vm.textOrImageOk },
          on: {
            input: function ($event) {
              return _vm.updateQuestionFormOk($event)
            },
          },
        },
        [
          _vm.showTopicInput
            ? _c(
                "div",
                [
                  _c(
                    "m-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "m-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-combobox", {
                            staticClass: "tag-select",
                            attrs: {
                              readonly: _vm.readonly,
                              outlined: "",
                              multiple: "",
                              items: _vm.topics,
                              label: !_vm.$language ? "Topic(s)" : "টপিক",
                              placeholder: !_vm.$language
                                ? "Enter one or multiple tags...(i.e. not more than 3 tags)"
                                : "এক বা একাধিক ট্যাগ লিখুন...(i.e. ৩টির বেশি নয়)",
                              chips: "",
                              "deletable-chips": "",
                              "append-icon": "",
                              rules: [
                                _vm.atLeastOne,
                                _vm.threeOrLess,
                                _vm.textLength,
                              ],
                            },
                            on: { input: _vm.enterTopicObject },
                            model: {
                              value: _vm.formData.topics,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "topics", $$v)
                              },
                              expression: "formData.topics",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "content-header mb-5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$language ? "Question Details" : "প্রশ্নের বিবরণ"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "m-row",
            [
              _c(
                "m-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-select", {
                    attrs: {
                      readonly: _vm.readonly,
                      outlined: "",
                      "append-icon": "keyboard_arrow_down",
                      placeholder: !_vm.$language
                        ? "Select the type of question..."
                        : "প্রশ্নের ধরন নির্বাচন করুন...",
                      items: !_vm.$language
                        ? _vm.questionTypes
                        : _vm.questionTypesBengali,
                      "item-value": "id",
                      "item-text": "value",
                      label: !_vm.$language ? "Question Type" : "প্রশ্নের ধরন",
                      "data-qa": "question-type",
                      rules: _vm.questionTypeRule,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updateQuestionType()
                      },
                    },
                    model: {
                      value: _vm.formData.questionType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "questionType", $$v)
                      },
                      expression: "formData.questionType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "m-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-select", {
                    attrs: {
                      readonly: _vm.readonly,
                      outlined: "",
                      "append-icon": "keyboard_arrow_down",
                      placeholder: !_vm.$language
                        ? "Select the level of difficulty..."
                        : "ডিফিকাল্টি লেভেল সিলেক্ট করুন...",
                      items: _vm.difficultyLevels,
                      "item-text": !_vm.$language ? "en" : "bn",
                      "item-value": "en",
                      label: !_vm.$language ? "Difficulty" : "ডিফিকাল্টি",
                      "data-qa": "question-difficulty",
                      rules: _vm.difficultyRule,
                    },
                    model: {
                      value: _vm.formData.difficulty,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "difficulty", $$v)
                      },
                      expression: "formData.difficulty",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "m-row",
            [
              _c(
                "m-col",
                {
                  staticStyle: { position: "relative", padding: "0px" },
                  attrs: { cols: "8" },
                },
                [
                  _vm.readonly && _vm.formData.text
                    ? _c("div", {
                        staticClass:
                          "ml-4 mt-4 pa-12 border-2 border-default border-solid border-radius-8",
                        attrs: { id: "found-you" },
                        domProps: {
                          innerHTML: _vm._s(_vm.format(_vm.formData.text)),
                        },
                      })
                    : _c("quill-editor", {
                        ref: "quillEditor",
                        staticClass:
                          "v-textarea ml-3 mt-3 rounded border-t-0 border-solid",
                        class: _vm.textOrImageValidationError
                          ? "border-2 border-decline quill-error"
                          : "border border-default",
                        attrs: {
                          id: "formDetailsQuillEditor",
                          disabled: _vm.readonly,
                          counter: "8196",
                          maxlength: "8196",
                          options: {
                            ..._vm.editorOptions,
                            placeholder:
                              "i.e. what is the name of the capital of Bangladesh?...",
                          },
                        },
                        on: {
                          change: _vm.updateTextOrImageOk,
                          click: function ($event) {
                            _vm.showEquationField = true
                          },
                          input: _vm.onQuillInput,
                          focus: function ($event) {
                            return _vm.setCursorPosition()
                          },
                        },
                        model: {
                          value: _vm.formData.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "text", $$v)
                          },
                          expression: "formData.text",
                        },
                      }),
                  _c(
                    "div",
                    {
                      staticClass: "quill-label",
                      class: { "quill-error": _vm.textOrImageValidationError },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(!_vm.$language ? "Question" : "প্রশ্ন") +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticStyle: { height: "40px" } }, [
                    _vm.textOrImageValidationError
                      ? _c(
                          "span",
                          { staticClass: "xl:text-xs decline--text ml-8" },
                          [
                            _vm._v(
                              _vm._s(
                                !_vm.$language
                                  ? "Either text or image is mandatory."
                                  : "টেক্সট অথবা ছবি বাধ্যতামূলক।"
                              )
                            ),
                          ]
                        )
                      : _vm.textLengthNotOk && !_vm.textOrImageValidationError
                      ? _c(
                          "span",
                          { staticClass: "xl:text-xs decline--text ml-8" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Text must be less than 4096 characters."
                                    : "টেক্স্ট অবশ্যই ৪০৯৬ অক্ষর এর কম হতে হবে।"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              !_vm.readonly
                ? _c(
                    "m-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            dark: "",
                            depressed: "",
                            rounded: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showEquationField = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? "Add Equation"
                                : "সমীকরণ যোগ করুন"
                            ) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "400px", height: "400px" },
              model: {
                value: _vm.showEquationField,
                callback: function ($$v) {
                  _vm.showEquationField = $$v
                },
                expression: "showEquationField",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$language
                            ? "Insert equation:"
                            : "সমীকরণ ইনসার্ট করুন"
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ma-2",
                      staticStyle: {
                        "border-style": "solid",
                        "border-width": "thin",
                        "border-radius": "0.5em",
                        "border-color": "#c7c7c7",
                      },
                    },
                    [
                      _c("math-field", {
                        ref: "equationField",
                        attrs: {
                          value: _vm.equationText,
                          "virtual-keyboard-mode": "onfocus",
                        },
                        on: {
                          change: (v) => (_vm.equationText = v.target.value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            depressed: "",
                            rounded: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.addEquationToQuestion()
                              _vm.showEquationField = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.$language ? "Insert" : "ইনসার্ট করুন"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red",
                            rounded: "",
                            depressed: "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showEquationField = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন") +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.readonly && _vm.questionDetails.image
            ? _c(
                "div",
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.questionDetails.image.imageURL,
                      "max-height": "300",
                      "max-width": "200",
                      contain: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isImageInputDisabled
            ? _c(
                "v-row",
                {
                  attrs: { "no-gutters": "" },
                  on: {
                    blur: function ($event) {
                      return this.$refs.form.validate()
                    },
                  },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c("p", { staticClass: "static" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? "Add an image (optional):"
                                : "ছবি যোগ করুন (ঐচ্ছিক):"
                            )
                          ),
                        ]),
                        _vm._v(" " + _vm._s(_vm.imageFileName) + " "),
                      ]),
                      _c("image-input", {
                        staticClass: "mt-3",
                        staticStyle: { width: "50px" },
                        attrs: {
                          "data-qa": "question-image",
                          rules: _vm.imageRules,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateTextOrImageOk()
                          },
                          validation: _vm.valid_image,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "m-button",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.formData.questionType ===
                                              "fib",
                                            rounded: "",
                                            text: "",
                                            color: "primary",
                                            "data-qa": "question-image-button",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("image ")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !_vm.$language
                                                  ? "Select an image"
                                                  : "ছবি সিলেক্ট করুন"
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1306604579
                        ),
                        model: {
                          value: _vm.formData.image,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "image", $$v)
                          },
                          expression: "formData.image",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-row", { attrs: { "no-gutters": "" } }, [
            _c(
              "span",
              {
                staticClass:
                  "v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text",
              },
              [_vm._v(_vm._s(_vm.imageValidationMessage))]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }