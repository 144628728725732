<template>
  <m-container class="question-answer-form px-7 py-4">
    <div class="content-header mt-3 mb-1">
      {{ !$language ? "Answers" : "উত্তর" }}
    </div>
    <div class="mb-8">
      {{
        !$language
          ? "Type in answers option below. You can also add an explanation for each answer."
          : "নীচের উত্তর অংশে টাইপ করুন। আপনি প্রতিটি উত্তরের জন্য একটি ব্যাখ্যা যোগ করতে পারেন।"
      }}
      {{
        questionType === "mcq" && !$language
          ? "At least one option has to be correct."
          : ""
      }}
      {{
        questionType === "mcq" && $language
          ? "অন্তত একটি অপশন সঠিক হতে হবে।"
          : ""
      }}
    </div>

    <v-form ref="answerDetailsForm" v-model="answerFormOk">
      <v-simple-table
        v-if="questionType === 'fib'"
        class="table-row-no-hover-background"
        data-qa="fib-answer-table"
      >
        <thead>
          <tr>
            <th>{{ !$language ? "Answer" : "উত্তর" }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(answer, i) in formData.answers" :key="i">
            <td>
              <div class="mt-5 mb-5">
                <div
                  v-if="answer.text && readonly"
                  style="
                    overflow: hidden;
                    max-height: 9vh;
                    display: inline-block;
                    min-width: 20vw;
                    border: 1px solid #cccccc;
                    border-radius: 10px;
                  "
                  class="ml-4 px-3"
                  v-html="format(answer.text)"
                ></div>
                <v-textarea
                  v-else
                  auto-grow
                  :rows="1"
                  :readonly="readonly"
                  :placeholder="
                    !$language ? 'Enter answer here...' : 'এখানে উত্তর লিখুন...'
                  "
                  outlined
                  :value="answer.text"
                  data-qa="answer-text-input"
                  :rules="[required]"
                  @change="(v) => (answer.text = v)"
                >
                  <!-- <template v-slot:message>
                    <div class="d-flex justify-end">
                      <v-btn
                        v-if="!readonly"
                        v-show="!isExplanationVisible[i]"
                        color="primary"
                        text
                        small
                        class="mb-2"
                        @click="showExplanationForm(i)"
                        ><v-icon left>add</v-icon
                        >{{
                          !$language ? "Add explanation" : "ব্যাখ্যা যোগ করুন"
                        }}
                      </v-btn>
                    </div>
                  </template> -->
                </v-textarea>
                <div
                  class="d-flex justify-end"
                  style="position: relative; bottom: 28px"
                >
                  <v-btn
                    v-if="!readonly"
                    v-show="!isExplanationVisible[i]"
                    color="primary"
                    text
                    small
                    class="mb-2"
                    @click="showExplanationForm(i)"
                    ><v-icon left>add</v-icon
                    >{{ !$language ? "Add explanation" : "ব্যাখ্যা যোগ করুন" }}
                  </v-btn>
                </div>
                <v-textarea
                  v-if="!readonly && isExplanationVisible[i]"
                  :rows="2"
                  auto-grow
                  :readonly="readonly"
                  solo
                  flat
                  :placeholder="
                    !$language
                      ? 'Enter explanation here...'
                      : 'এখানে ব্যাখ্যা লিখুন'
                  "
                  background-color="#F6FCFF"
                  color="black"
                  messages="this is to show remove explanation button"
                  :value="answer.explanation"
                  @change="(v) => (answer.explanation = v)"
                >
                  <template v-slot:message>
                    <div class="d-flex justify-end">
                      <v-btn
                        v-show="isExplanationVisible[i]"
                        color="red lighten-1"
                        text
                        small
                        class="mb-2"
                        @click="hideExplanationForm(i)"
                        ><v-icon left>close</v-icon
                        >{{
                          !$language
                            ? "Remove explanation"
                            : "ব্যাখ্যা রিমুভ করুন"
                        }}
                      </v-btn>
                    </div>
                  </template>
                </v-textarea>
                <br />
                <!-- <v-flex
                  v-if="readonly && answer.explanation"
                  style="
                    overflow: hidden;
                    max-height: 9vh;
                    display: inline-block;
                    min-width: 20vw;
                    border-radius: 10px;
                    background-color: #f6fcff;
                  "
                  class="ml-4 px-3"
                  v-html="format(answer.explanation)"
                >
                </v-flex> -->
                <div
                  v-if="readonly && answer.explanation"
                  class="ml-4 px-3 overflow-hidden d-inline-block rounded-lg"
                  style="
                    max-height: 9vh;
                    min-width: 20vw;
                    background-color: #f6fcff;
                  "
                  v-html="format(answer.explanation)"
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table
        v-if="questionType === 'mcq'"
        class="table-row-no-hover-background"
        data-qa="answer-table"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{ !$language ? "Correct" : "সঠিক" }}</th>
              <th style="width: 50%">{{ !$language ? "Options" : "অপশন" }}</th>
              <th class="d-flex justify-center py-4">
                {{
                  !$language
                    ? "Upload image (optional)"
                    : "ছবি আপলোড করুন (ঐচ্ছিক)"
                }}
              </th>
              <th>{{ !$language ? "Action" : "পরিবর্তনF" }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="formData.answers.length === 0">
              <td class="caption text-center" colspan="4">
                {{
                  !$language
                    ? "No answers added yet"
                    : "কোন উত্তর এখনো যোগ করা হয়নি। "
                }}
              </td>
            </tr>
            <tr
              v-for="(answer, i) in formData.answers"
              :key="i"
              data-qa="answer"
            >
              <td>
                <v-switch
                  v-model="answer.is_correct"
                  :readonly="readonly"
                  data-qa="answer-is-correct"
                ></v-switch>
              </td>
              <td>
                <div class="mt-5 mb-5">
                  <div
                    v-if="readonly && answer.text"
                    class="mt-4 pa-3 boder border-solid border-default rounded-xl"
                    v-html="format(answer.text)"
                  ></div>
                  <v-textarea
                    v-else
                    auto-grow
                    :readonly="readonly"
                    :rows="1"
                    :placeholder="
                      !$language ? 'Enter answer here...' : 'এখানে উত্তর লিখুন'
                    "
                    outlined
                    :value="answer.text"
                    data-qa="answer-text-input"
                    :rules="rules.mcqRules"
                    @change="(v) => (answer.text = v)"
                  >
                  </v-textarea>
                  <div
                    class="d-flex justify-end"
                    style="position: relative; bottom: 28px"
                  >
                    <v-btn
                      v-if="!readonly"
                      v-show="!isExplanationVisible[i]"
                      color="primary"
                      text
                      small
                      class="mb-2"
                      @click="showExplanationForm(i)"
                      ><v-icon left>add</v-icon
                      >{{
                        !$language ? "Add explanation" : "ব্যাখ্যা যোগ করুন"
                      }}
                    </v-btn>
                  </div>
                  <v-textarea
                    v-if="!readonly && isExplanationVisible[i]"
                    v-model="answer.explanation"
                    :rows="2"
                    auto-grow
                    :readonly="readonly"
                    solo
                    flat
                    :placeholder="
                      !$language
                        ? 'Enter explanation here...'
                        : 'এখানে ব্যাখ্যা লিখুন'
                    "
                    background-color="#F6FCFF"
                    color="black"
                    messages="this is to show remove explanation button"
                    :value="answer.explanation"
                    @change="(v) => (answer.explanation = v)"
                  >
                    <template v-slot:message>
                      <span
                        v-show="answer.explanation.length > 1000"
                        style="color: red"
                        >Explanation must be less than 1000 characters
                        long</span
                      >
                      <div class="d-flex justify-end">
                        <v-btn
                          v-show="isExplanationVisible[i]"
                          color="red lighten-1"
                          text
                          small
                          class="mb-2"
                          @click="hideExplanationForm(i)"
                          ><v-icon left>close</v-icon
                          >{{
                            !$language
                              ? "Remove explanation"
                              : "ব্যাখ্যা রিমুভ করুন"
                          }}
                        </v-btn>
                      </div>
                    </template>
                  </v-textarea>
                  <!-- <v-flex
                    v-if="readonly && answer.explanation"
                    style="
                      overflow: hidden;
                      max-height: 9vh;
                      display: inline-block;
                      min-width: 20vw;
                      border-radius: 10px;
                      background-color: #f6fcff;
                    "
                    class="px-3 mt-2"
                    v-html="format(answer.explanation)"
                  >
                  </v-flex> -->
                  <div
                    v-if="readonly && answer.explanation"
                    style="
                      max-height: 9vh;
                      min-width: 20vw;
                      background-color: #f6fcff;
                    "
                    class="px-3 mt-2 overflow-hidden d-inline-block rounded-lg"
                    v-html="format(answer.explanation)"
                  ></div>
                </div>
              </td>
              <td>
                <div v-if="readonly && answer.image.imageURL">
                  <v-img
                    :src="answer.image.imageURL"
                    max-height="150"
                    max-width="150"
                    contain
                  ></v-img>
                </div>
                <image-input
                  v-if="!readonly"
                  v-model="answer.image"
                  height="70"
                  width="70"
                  data-qa="answer-image-input"
                  class="d-flex align-center justify-center flex-column"
                  :rules="rules.imageRules"
                  @validation="valid_image"
                >
                  <template v-slot:activator>
                    <m-button
                      text
                      color="primary"
                      data-qa="question-image-button"
                      ><v-icon left>image</v-icon
                      >{{
                        !$language ? "Select an image" : "ইমেজ সিলেক্ট করুন"
                      }}
                    </m-button>
                  </template>
                </image-input>
                <v-row
                  ><span
                    class="v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text"
                    >{{ imageValidationMessage }}</span
                  ></v-row
                >
              </td>
              <td>
                <v-btn
                  v-if="!readonly"
                  icon
                  data-qa="answer-delete-button"
                  @click="deleteAnswer(i)"
                  ><v-icon>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <m-row>
        <m-col v-if="!readonly && questionType === 'mcq'">
          <m-button
            outlined
            rounded
            color="primary"
            data-qa="answer-add-button"
            @click="addAnswer"
            ><v-icon left>add</v-icon
            >{{ !$language ? "Add another option" : "আরেকটি অপশন যোগ করুন" }}
          </m-button>
        </m-col>
      </m-row>
    </v-form>
  </m-container>
</template>

<script>
const math = /\$([^$]*)\$/g;

import katex from "katex/dist/katex.mjs";
import ImageInput from "~ecf/components/ImageInput";
import { some } from "lodash";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "SingleQuestionFormAnswers",
  components: { ImageInput },
  model: {
    prop: "answers",
  },
  props: {
    answers: {
      type: Array,
      required: true,
    },
    questionType: {
      type: String,
      default: "mcq",
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [FieldValidations],
  data() {
    return {
      imageValidationMessage: undefined,
      formData: {
        answers: Array.from(this.answers),
      },
      isExplanationVisible: [],
      answerFormOk: this.isValid,
      rules: {
        imageRules: [
          (v) =>
            this.fileSizeLessThanOrEqual(
              v,
              5 * 1024 * 1024,
              "Question Image Size"
            ),
        ],
        mcqRules: [
          (v) => this.required(v), // does not work without this syntax
          () => this.atLeastOneCorrect || "At least one answer must be correct",
        ],
      },
    };
  },
  computed: {
    atLeastOneCorrect() {
      if (this.questionType === "fib") return true;
      return (
        this.formData.answers.length > 0 &&
        some(this.formData.answers, (a) => a.is_correct)
      );
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        for (var i = 0; i < value.answers.length; i++) {
          value.answers[i].text = value.answers[i].text || undefined;
        }
        this.$emit("input", value.answers);
        this.$refs.answerDetailsForm.validate();
        this.$emit(
          "update:is-valid",
          this.answerFormOk && this.atLeastOneCorrect
        );
      },
    },
    answerFormOk() {
      this.$emit(
        "update:is-valid",
        this.answerFormOk && this.atLeastOneCorrect
      );
    },
    questionType: {
      handler() {
        this.formData.answers = [];
        this.addAnswer();
      },
    },
  },
  methods: {
    valid_image(value) {
      this.imageValidationMessage = value;
    },
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" },
          });
          return rendered;
        });
      } else {
        return text;
      }
    },
    validateAnswer(index) {
      return [
        (v) =>
          !!v ||
          !!this.formData.answers[index].image.file ||
          "Either answer text or an image is required",
      ];
    },
    deleteAnswer(i) {
      this.formData.answers.splice(i, 1);
      this.isExplanationVisible.splice(i, 1);
    },
    addAnswer() {
      if (this.questionType === "fib") {
        this.formData.answers.push({
          text: undefined,
          is_correct: true,
          image: { imageURL: undefined, file: undefined },
          explanation: undefined,
        });
      } else {
        this.formData.answers.push({
          text: undefined,
          is_correct: false,
          image: { imageURL: undefined, file: undefined },
          explanation: undefined,
        });
      }
      this.isExplanationVisible.push(false);
    },
    showExplanationForm(idx) {
      this.$set(this.isExplanationVisible, idx, true);
    },
    hideExplanationForm(idx) {
      this.$set(this.isExplanationVisible, idx, false);
      this.$set(this.formData.answers[idx], "explanation", undefined);
    },
    async validate(){
      await this.$refs.answerDetailsForm.validate();
    }
  },
};
</script>

<style></style>
