var render = function render(_c, _vm) {
  return _c(
    "m-container",
    { staticClass: "stepper-navigation" },
    [
      _c(
        "m-row",
        { staticClass: "px-5", attrs: { justify: "space-between" } },
        [
          _c(
            "div",
            [
              _vm._t("left", function () {
                return [
                  _c(
                    "m-rounded-button",
                    {
                      staticClass: "btn-outlined",
                      attrs: {
                        disabled: _vm.props.previousDisabled,
                        outlined: "",
                        "data-qa": "previous-button",
                      },
                      on: { click: _vm.listeners.previous },
                    },
                    [
                      _c("m-icon", { attrs: { left: "" } }, [
                        _vm._v("keyboard_arrow_left"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.props.language ? "Previous" : "ফিরে যান"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "m-rounded-button",
                    {
                      staticClass: "ml-2 btn-outlined",
                      attrs: {
                        disabled: _vm.props.nextDisabled,
                        outlined: "",
                        "data-qa": "next-button",
                      },
                      on: { click: _vm.listeners.next },
                    },
                    [
                      _vm._v(
                        _vm._s(!_vm.props.language ? "Next" : "এগিয়ে যান") + " "
                      ),
                      _c("m-icon", { attrs: { right: "" } }, [
                        _vm._v("keyboard_arrow_right"),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c("div", [_vm._t("right")], 2),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }