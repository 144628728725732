<template>
  <m-container class="px-7 py-4">
    <v-form
      ref="questionDetailsForm"
      :value="questionFormOk && textOrImageOk"
      @input="updateQuestionFormOk($event)"
    >
      <div v-if="showTopicInput">
        <m-row class="mt-3">
          <m-col cols="6">
            <v-combobox
              v-model="formData.topics"
              :readonly="readonly"
              outlined
              multiple
              :items="topics"
              :label="!$language ? 'Topic(s)' : 'টপিক'"
              :placeholder="
                !$language
                  ? 'Enter one or multiple tags...(i.e. not more than 3 tags)'
                  : 'এক বা একাধিক ট্যাগ লিখুন...(i.e. ৩টির বেশি নয়)'
              "
              chips
              deletable-chips
              class="tag-select"
              append-icon=""
              :rules="[atLeastOne, threeOrLess, textLength]"
              @input="enterTopicObject"
            >
            </v-combobox>
          </m-col>
        </m-row>

        <div class="content-header mb-5">
          {{ !$language ? "Question Details" : "প্রশ্নের বিবরণ" }}
        </div>
      </div>
      <m-row>
        <m-col cols="5">
          <v-select
            v-model="formData.questionType"
            :readonly="readonly"
            outlined
            append-icon="keyboard_arrow_down"
            :placeholder="
              !$language
                ? 'Select the type of question...'
                : 'প্রশ্নের ধরন নির্বাচন করুন...'
            "
            :items="!$language ? questionTypes : questionTypesBengali"
            item-value="id"
            item-text="value"
            :label="!$language ? 'Question Type' : 'প্রশ্নের ধরন'"
            data-qa="question-type"
            :rules="questionTypeRule"
            @change="updateQuestionType()"
          ></v-select>
        </m-col>
        <m-col cols="5">
          <v-select
            v-model="formData.difficulty"
            :readonly="readonly"
            outlined
            append-icon="keyboard_arrow_down"
            :placeholder="
              !$language
                ? 'Select the level of difficulty...'
                : 'ডিফিকাল্টি লেভেল সিলেক্ট করুন...'
            "
            :items="difficultyLevels"
            :item-text="!$language ? 'en' : 'bn'"
            item-value="en"
            :label="!$language ? 'Difficulty' : 'ডিফিকাল্টি'"
            data-qa="question-difficulty"
            :rules="difficultyRule"
          ></v-select>
        </m-col>
      </m-row>
      <m-row>
        <m-col cols="8" style="position: relative; padding: 0px">
          <div
            id="found-you"
            v-if="readonly && formData.text"
            class="ml-4 mt-4 pa-12 border-2 border-default border-solid border-radius-8"
            v-html="format(formData.text)"
          ></div>
          <quill-editor
            v-else
            id="formDetailsQuillEditor"
            ref="quillEditor"
            v-model="formData.text"
            :disabled="readonly"
            counter="8196"
            maxlength="8196"
            :options="{
              ...editorOptions,
              placeholder:
                'i.e. what is the name of the capital of Bangladesh?...'
            }"
            class="v-textarea ml-3 mt-3 rounded border-t-0 border-solid"
            :class="
              textOrImageValidationError
                ? 'border-2 border-decline quill-error'
                : 'border border-default'
            "
            @change="updateTextOrImageOk"
            @click="showEquationField = true"
            @input="onQuillInput"
            @focus="setCursorPosition()"
          ></quill-editor>
          <div
            class="quill-label"
            :class="{ 'quill-error': textOrImageValidationError }"
          >
            {{ !$language ? "Question" : "প্রশ্ন" }}
          </div>
          <div style="height: 40px">
            <span
              v-if="textOrImageValidationError"
              class="xl:text-xs decline--text ml-8"
              >{{
                !$language
                  ? "Either text or image is mandatory."
                  : "টেক্সট অথবা ছবি বাধ্যতামূলক।"
              }}</span
            >
            <span
              v-else-if="textLengthNotOk && !textOrImageValidationError"
              class="xl:text-xs decline--text ml-8"
            >
              {{
                !$language
                  ? "Text must be less than 4096 characters."
                  : "টেক্স্ট অবশ্যই ৪০৯৬ অক্ষর এর কম হতে হবে।"
              }}
            </span>
          </div>
        </m-col>
        <m-col v-if="!readonly">
          <v-btn
            color="primary"
            dark
            depressed
            rounded
            @click="showEquationField = true"
            >{{ !$language ? "Add Equation" : "সমীকরণ যোগ করুন" }}
          </v-btn>
        </m-col>
      </m-row>
      <v-dialog v-model="showEquationField" width="400px" height="400px">
        <v-card>
          <v-card-title class="headline">
            {{ !$language ? "Insert equation:" : "সমীকরণ ইনসার্ট করুন" }}
          </v-card-title>
          <div
            class="ma-2"
            style="
              border-style: solid;
              border-width: thin;
              border-radius: 0.5em;
              border-color: #c7c7c7;
            "
          >
            <math-field
              ref="equationField"
              :value="equationText"
              virtual-keyboard-mode="onfocus"
              @change="v => (equationText = v.target.value)"
            ></math-field>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              rounded
              @click="
                addEquationToQuestion();
                showEquationField = false;
              "
            >
              {{ !$language ? "Insert" : "ইনসার্ট করুন" }}
            </v-btn>
            <v-btn
              color="red"
              rounded
              depressed
              outlined
              @click="showEquationField = false"
            >
              {{ !$language ? "Cancel" : "বাতিল করুন" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="readonly && questionDetails.image">
        <v-img
          :src="questionDetails.image.imageURL"
          max-height="300"
          max-width="200"
          contain
        ></v-img>
      </div>
      <v-row
        v-if="!isImageInputDisabled"
        @blur="this.$refs.form.validate()"
        no-gutters
      >
        <v-col class="pt-0">
          <p class="static">
            <span>{{
              !$language ? "Add an image (optional):" : "ছবি যোগ করুন (ঐচ্ছিক):"
            }}</span>
            {{ imageFileName }}
          </p>
          <image-input
            v-model="formData.image"
            class="mt-3"
            style="width: 50px"
            data-qa="question-image"
            @input="updateTextOrImageOk()"
            :rules="imageRules"
            @validation="valid_image"
          >
            <template v-slot:activator>
              <div>
                <m-button
                  :disabled="formData.questionType === 'fib'"
                  rounded
                  text
                  color="primary"
                  data-qa="question-image-button"
                >
                  <v-icon left>image </v-icon>
                  {{ !$language ? "Select an image" : "ছবি সিলেক্ট করুন" }}
                </m-button>
              </div>
            </template>
            <!--<template v-slot:placeholder>
            <m-icon size="64">image</m-icon>
          </template>-->
          </image-input>
        </v-col>
      </v-row>
      <v-row no-gutters
        ><span
          class="v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text"
          >{{ imageValidationMessage }}</span
        >
      </v-row>
    </v-form>
  </m-container>
</template>

<script>
const math = /\$([^$]*)\$/g;

import katex from "katex/dist/katex.mjs";
import ImageInput from "~ecf/components/ImageInput";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "SingleQuestionFormDetails",
  components: { ImageInput },
  model: {
    prop: "questionDetails"
  },
  mixins: [FieldValidations],
  props: {
    showTopicInput: {
      default: false,
      type: Boolean
    },
    topics: {
      type: Array,
      default: () => []
    },
    questionDetails: {
      type: Object,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      textOrImageValidationError: false,
      imageValidationMessage: undefined,
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },
      cursorPosInText: 0,
      lastPlainTextSize: 0,
      equationsArray: [],
      showEquationField: false,
      containsLatex: false,
      formData: { ...this.questionDetails },
      equationText: "",
      isTopicFormVisible: false,
      questionTypes: [
        { id: "mcq", value: "Multiple Choice Question" },
        {
          value: "Fill In The Blanks Question",
          id: "fib"
        },
        {
          id: "desc",
          value: "Descriptive Question"
        }
      ],
      questionTypesBengali: [
        { id: "mcq", value: "বহুনির্বাচনী প্রশ্ন" },
        {
          value: "শূন্যস্থান পূরণ",
          id: "fib"
        },
        {
          id: "desc",
          value: "বর্ণনামূলক প্রশ্ন"
        }
      ],

      difficultyLevels: [
        { en: "Easy", bn: "সহজ" },
        { en: "Medium", bn: "মধ্যম" },
        { en: "Hard", bn: "কঠিন" }
      ],

      questionFormOk: this.isValid,
      textOrImageOk: this.isValid,
      textLengthNotOk: false,
      questionTextRules: [
        v => {
          return (
            (!!v && v.length >= 1) ||
            !!this.formData.image.file ||
            "Either question text or an image is required"
          );
        }
      ],
      questionTypeRule: [v => this.required(v)],
      difficultyRule: [v => this.required(v)], // does not work without this syntax
      imageRules: [
        v =>
          this.fileSizeLessThanOrEqual(
            v,
            5 * 1024 * 1024,
            "Question Image Size"
          )
      ]
    };
  },
  computed: {
    isImageInputDisabled() {
      return this.readonly || this.formData.questionType === "fib";
    },
    imageFileName() {
      return this.formData.image.file
        ? this.formData.image.file.name
        : !this.$language
        ? "No Image Selected"
        : "কোনো ছবি সিলেক্ট করা হয়নি";
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        value.text = value.text || undefined;
        this.$emit("input", value);
      }
    }
  },
  methods: {
    valid_image(value) {
      this.imageValidationMessage = value;
    },
    enterTopicObject() {
      this.formData.topics = this.formData.topics.map(topic => {
        if (typeof topic === "string") {
          return { text: topic };
        } else {
          return topic;
        }
      });
    },
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" }
          });
          return rendered;
        });
      } else {
        return text;
      }
    },
    updateImage(value) {
      value = value || { imageURL: null, file: null };
      this.$set(this.formData, "image", value);
      this.updateTextOrImageOk();
    },
    updateQuestionFormOk(val) {
      this.questionFormOk = val;
      this.updateQuestionOk();
    },
    updateTextOrImageOk() {
      if (this.$refs.quillEditor.quill.getLength() - 1 > 4096) {
        this.textLengthNotOk = true;
      } else {
        this.textLengthNotOk = false;
      }
      if (this.$refs.quillEditor.quill.getLength() - 1 === 0) {
        // no question text has been entered
        this.textOrImageOk = !!this.formData.image.imageURL;
      } else {
        // some text has been entered, so at least one character has
        this.textOrImageOk = true;
      }
      this.textOrImageValidationError = !this.textOrImageOk;
      this.updateQuestionOk();
    },
    updateQuestionOk(val) {
      this.$emit(
        "update:is-valid",
        this.questionFormOk && this.textOrImageOk && !this.textLengthNotOk
      );
    },
    setCursorPosition() {
      let curPos = null;
      let selectionAvailable = this.$refs.quillEditor.quill.getSelection();
      if (selectionAvailable != null) curPos = selectionAvailable.index;
      if (curPos !== null) this.cursorPosInText = curPos;
    },
    showKeyboard() {
      this.showEquationField = true;
    },
    onQuillInput() {
      this.setCursorPosition();
    },
    onQuillFocus($event, ref) {
      this.$refs[ref].$el.classList.add("quill-focused");
    },
    onQuillBlur($event, ref) {
      this.$refs[ref].$el.classList.remove("quill-focused");
    },
    showOrHideMathField() {
      if (this.formData.text === undefined) this.formData.text = "";
      this.showEquationField = !this.showEquationField;
    },

    addEquationToQuestion() {
      let equation = "";
      if (this.equationText !== "" || this.equationText !== undefined) {
        equation = `<span class="ql-formula" data-value=${this.equationText}></span>`;
      }
      this.$refs.quillEditor.quill.clipboard.dangerouslyPasteHTML(
        this.cursorPosInText,
        equation
      );
      this.equationText = "";
      this.$refs.equationField.value = "";
      this.setCursorPosition();
      this.$refs.quillEditor.quill.focus();
    },
    atLeastOne(value) {
      return this.required(value.length > 0);
    },
    threeOrLess(value) {
      return this.maxValue(value.length, 3, "Tags attached");
    },
    textLength(value) {
      let errMsg = "";
      value.forEach(element => {
        errMsg = this.betweenValue(
          element.text.length,
          3,
          50,
          true,
          "Question tag length"
        );
      });
      return errMsg;
    },
    updateTags() {
      this.$nextTick(() => {
        this.formData.topics.push(...this.search.split(","));
      });
    },
    updateQuestionType() {
      this.$emit("updateqt", this.formData.questionType);
    },
    clearImage() {
      this.formData.image = undefined;
    },
    async validate() {
      await this.$refs.questionDetailsForm.validate();
      this.updateTextOrImageOk();
    }
  }
};
</script>

<style lang="scss">
.katex-display {
  display: inline-block !important;
}

.ML__keyboard.is-visible {
  //border: 5px solid red;
  z-index: 202;
}

.quill-label {
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: white;
  padding: 4px 6px 0px;
  font-size: 0.7rem;
  color: #787878;
}

.quill-error {
  color: $decline;
}

.quill-error .ql-toolbar.ql-snow {
  border-color: $decline;
  border-top-width: 2px;
}

.ql-toolbar.ql-snow {
  border-left-width: 0px;
  border-right-width: 0px;
}

.tag-input span.chip {
  font-size: 0.9em;
}

.tag-input span.v-chip {
  font-size: 0.9em;
  padding-left: 7px;
}
</style>
