var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "question-answer-form px-7 py-4" },
    [
      _c("div", { staticClass: "content-header mt-3 mb-1" }, [
        _vm._v(" " + _vm._s(!_vm.$language ? "Answers" : "উত্তর") + " "),
      ]),
      _c("div", { staticClass: "mb-8" }, [
        _vm._v(
          " " +
            _vm._s(
              !_vm.$language
                ? "Type in answers option below. You can also add an explanation for each answer."
                : "নীচের উত্তর অংশে টাইপ করুন। আপনি প্রতিটি উত্তরের জন্য একটি ব্যাখ্যা যোগ করতে পারেন।"
            ) +
            " " +
            _vm._s(
              _vm.questionType === "mcq" && !_vm.$language
                ? "At least one option has to be correct."
                : ""
            ) +
            " " +
            _vm._s(
              _vm.questionType === "mcq" && _vm.$language
                ? "অন্তত একটি অপশন সঠিক হতে হবে।"
                : ""
            ) +
            " "
        ),
      ]),
      _c(
        "v-form",
        {
          ref: "answerDetailsForm",
          model: {
            value: _vm.answerFormOk,
            callback: function ($$v) {
              _vm.answerFormOk = $$v
            },
            expression: "answerFormOk",
          },
        },
        [
          _vm.questionType === "fib"
            ? _c(
                "v-simple-table",
                {
                  staticClass: "table-row-no-hover-background",
                  attrs: { "data-qa": "fib-answer-table" },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(!_vm.$language ? "Answer" : "উত্তর")),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.formData.answers, function (answer, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "mt-5 mb-5" },
                            [
                              answer.text && _vm.readonly
                                ? _c("div", {
                                    staticClass: "ml-4 px-3",
                                    staticStyle: {
                                      overflow: "hidden",
                                      "max-height": "9vh",
                                      display: "inline-block",
                                      "min-width": "20vw",
                                      border: "1px solid #cccccc",
                                      "border-radius": "10px",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.format(answer.text)
                                      ),
                                    },
                                  })
                                : _c("v-textarea", {
                                    attrs: {
                                      "auto-grow": "",
                                      rows: 1,
                                      readonly: _vm.readonly,
                                      placeholder: !_vm.$language
                                        ? "Enter answer here..."
                                        : "এখানে উত্তর লিখুন...",
                                      outlined: "",
                                      value: answer.text,
                                      "data-qa": "answer-text-input",
                                      rules: [_vm.required],
                                    },
                                    on: { change: (v) => (answer.text = v) },
                                  }),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-end",
                                  staticStyle: {
                                    position: "relative",
                                    bottom: "28px",
                                  },
                                },
                                [
                                  !_vm.readonly
                                    ? _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.isExplanationVisible[i],
                                              expression:
                                                "!isExplanationVisible[i]",
                                            },
                                          ],
                                          staticClass: "mb-2",
                                          attrs: {
                                            color: "primary",
                                            text: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showExplanationForm(i)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("add")]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              !_vm.$language
                                                ? "Add explanation"
                                                : "ব্যাখ্যা যোগ করুন"
                                            ) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.readonly && _vm.isExplanationVisible[i]
                                ? _c("v-textarea", {
                                    attrs: {
                                      rows: 2,
                                      "auto-grow": "",
                                      readonly: _vm.readonly,
                                      solo: "",
                                      flat: "",
                                      placeholder: !_vm.$language
                                        ? "Enter explanation here..."
                                        : "এখানে ব্যাখ্যা লিখুন",
                                      "background-color": "#F6FCFF",
                                      color: "black",
                                      messages:
                                        "this is to show remove explanation button",
                                      value: answer.explanation,
                                    },
                                    on: {
                                      change: (v) => (answer.explanation = v),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "message",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-end",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm
                                                              .isExplanationVisible[
                                                              i
                                                            ],
                                                          expression:
                                                            "isExplanationVisible[i]",
                                                        },
                                                      ],
                                                      staticClass: "mb-2",
                                                      attrs: {
                                                        color: "red lighten-1",
                                                        text: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.hideExplanationForm(
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [_vm._v("close")]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          !_vm.$language
                                                            ? "Remove explanation"
                                                            : "ব্যাখ্যা রিমুভ করুন"
                                                        ) + " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                              _c("br"),
                              _vm.readonly && answer.explanation
                                ? _c("div", {
                                    staticClass:
                                      "ml-4 px-3 overflow-hidden d-inline-block rounded-lg",
                                    staticStyle: {
                                      "max-height": "9vh",
                                      "min-width": "20vw",
                                      "background-color": "#f6fcff",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.format(answer.explanation)
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm.questionType === "mcq"
            ? _c("v-simple-table", {
                staticClass: "table-row-no-hover-background",
                attrs: { "data-qa": "answer-table" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Correct" : "সঠিক")
                                ),
                              ]),
                              _c("th", { staticStyle: { width: "50%" } }, [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Options" : "অপশন")
                                ),
                              ]),
                              _c(
                                "th",
                                { staticClass: "d-flex justify-center py-4" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.$language
                                          ? "Upload image (optional)"
                                          : "ছবি আপলোড করুন (ঐচ্ছিক)"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language ? "Action" : "পরিবর্তনF"
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm.formData.answers.length === 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "caption text-center",
                                        attrs: { colspan: "4" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              !_vm.$language
                                                ? "No answers added yet"
                                                : "কোন উত্তর এখনো যোগ করা হয়নি। "
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._l(
                                _vm.formData.answers,
                                function (answer, i) {
                                  return _c(
                                    "tr",
                                    { key: i, attrs: { "data-qa": "answer" } },
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              readonly: _vm.readonly,
                                              "data-qa": "answer-is-correct",
                                            },
                                            model: {
                                              value: answer.is_correct,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  answer,
                                                  "is_correct",
                                                  $$v
                                                )
                                              },
                                              expression: "answer.is_correct",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "mt-5 mb-5" },
                                          [
                                            _vm.readonly && answer.text
                                              ? _c("div", {
                                                  staticClass:
                                                    "mt-4 pa-3 boder border-solid border-default rounded-xl",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.format(answer.text)
                                                    ),
                                                  },
                                                })
                                              : _c("v-textarea", {
                                                  attrs: {
                                                    "auto-grow": "",
                                                    readonly: _vm.readonly,
                                                    rows: 1,
                                                    placeholder: !_vm.$language
                                                      ? "Enter answer here..."
                                                      : "এখানে উত্তর লিখুন",
                                                    outlined: "",
                                                    value: answer.text,
                                                    "data-qa":
                                                      "answer-text-input",
                                                    rules: _vm.rules.mcqRules,
                                                  },
                                                  on: {
                                                    change: (v) =>
                                                      (answer.text = v),
                                                  },
                                                }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-end",
                                                staticStyle: {
                                                  position: "relative",
                                                  bottom: "28px",
                                                },
                                              },
                                              [
                                                !_vm.readonly
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              !_vm
                                                                .isExplanationVisible[
                                                                i
                                                              ],
                                                            expression:
                                                              "!isExplanationVisible[i]",
                                                          },
                                                        ],
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          color: "primary",
                                                          text: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showExplanationForm(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [_vm._v("add")]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Add explanation"
                                                              : "ব্যাখ্যা যোগ করুন"
                                                          ) + " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            !_vm.readonly &&
                                            _vm.isExplanationVisible[i]
                                              ? _c("v-textarea", {
                                                  attrs: {
                                                    rows: 2,
                                                    "auto-grow": "",
                                                    readonly: _vm.readonly,
                                                    solo: "",
                                                    flat: "",
                                                    placeholder: !_vm.$language
                                                      ? "Enter explanation here..."
                                                      : "এখানে ব্যাখ্যা লিখুন",
                                                    "background-color":
                                                      "#F6FCFF",
                                                    color: "black",
                                                    messages:
                                                      "this is to show remove explanation button",
                                                    value: answer.explanation,
                                                  },
                                                  on: {
                                                    change: (v) =>
                                                      (answer.explanation = v),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "message",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      answer
                                                                        .explanation
                                                                        .length >
                                                                      1000,
                                                                    expression:
                                                                      "answer.explanation.length > 1000",
                                                                  },
                                                                ],
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Explanation must be less than 1000 characters long"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-end",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            _vm
                                                                              .isExplanationVisible[
                                                                              i
                                                                            ],
                                                                          expression:
                                                                            "isExplanationVisible[i]",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "mb-2",
                                                                    attrs: {
                                                                      color:
                                                                        "red lighten-1",
                                                                      text: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.hideExplanationForm(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "close"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        !_vm.$language
                                                                          ? "Remove explanation"
                                                                          : "ব্যাখ্যা রিমুভ করুন"
                                                                      ) + " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: answer.explanation,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        answer,
                                                        "explanation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "answer.explanation",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.readonly && answer.explanation
                                              ? _c("div", {
                                                  staticClass:
                                                    "px-3 mt-2 overflow-hidden d-inline-block rounded-lg",
                                                  staticStyle: {
                                                    "max-height": "9vh",
                                                    "min-width": "20vw",
                                                    "background-color":
                                                      "#f6fcff",
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.format(
                                                        answer.explanation
                                                      )
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _vm.readonly && answer.image.imageURL
                                            ? _c(
                                                "div",
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: answer.image
                                                        .imageURL,
                                                      "max-height": "150",
                                                      "max-width": "150",
                                                      contain: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.readonly
                                            ? _c("image-input", {
                                                staticClass:
                                                  "d-flex align-center justify-center flex-column",
                                                attrs: {
                                                  height: "70",
                                                  width: "70",
                                                  "data-qa":
                                                    "answer-image-input",
                                                  rules: _vm.rules.imageRules,
                                                },
                                                on: {
                                                  validation: _vm.valid_image,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "m-button",
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color:
                                                                  "primary",
                                                                "data-qa":
                                                                  "question-image-button",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "image"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                _vm._s(
                                                                  !_vm.$language
                                                                    ? "Select an image"
                                                                    : "ইমেজ সিলেক্ট করুন"
                                                                ) + " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: answer.image,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      answer,
                                                      "image",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "answer.image",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("v-row", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "v-messages__message v-messages__wrapper v-messages pl-4 pt-2 error--text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.imageValidationMessage
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          !_vm.readonly
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    "data-qa":
                                                      "answer-delete-button",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteAnswer(i)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("delete"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  421502906
                ),
              })
            : _vm._e(),
          _c(
            "m-row",
            [
              !_vm.readonly && _vm.questionType === "mcq"
                ? _c(
                    "m-col",
                    [
                      _c(
                        "m-button",
                        {
                          attrs: {
                            outlined: "",
                            rounded: "",
                            color: "primary",
                            "data-qa": "answer-add-button",
                          },
                          on: { click: _vm.addAnswer },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("add"),
                          ]),
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? "Add another option"
                                : "আরেকটি অপশন যোগ করুন"
                            ) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }