<template functional>
  <m-container class="stepper-navigation">
    <m-row class="px-5" justify="space-between">
      <div>
        <slot name="left">
          <m-rounded-button
            :disabled="props.previousDisabled"
            outlined
            data-qa="previous-button"
            class="btn-outlined"
            @click="listeners.previous"
          >
            <m-icon left>keyboard_arrow_left</m-icon>
            {{ !props.language ? "Previous" : "ফিরে যান" }}
          </m-rounded-button>
          <m-rounded-button
            :disabled="props.nextDisabled"
            outlined
            data-qa="next-button"
            class="ml-2 btn-outlined"
            @click="listeners.next"
            >{{ !props.language ? "Next" : "এগিয়ে যান" }}
            <m-icon right>keyboard_arrow_right</m-icon>
          </m-rounded-button>
        </slot>
      </div>
      <div>
        <slot name="right"> </slot>
      </div>
    </m-row>
  </m-container>
</template>

<script>
import { globalData } from "root-src/main";
export default {
  name: "TabNavigation",
  props: {
    nextDisabled: {
      type: Boolean,
      default: false
    },
    previousDisabled: {
      type: Boolean,
      default: false
    },
    language: {
      type: Boolean
    }
  }
};
</script>

<style></style>
