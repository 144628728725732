// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".katex-display {\n  display: inline-block !important;\n}\n.ML__keyboard.is-visible {\n  z-index: 202;\n}\n.quill-label {\n  position: absolute;\n  top: 0px;\n  left: 20px;\n  background-color: white;\n  padding: 4px 6px 0px;\n  font-size: 0.7rem;\n  color: #787878;\n}\n.quill-error {\n  color: #ff4848;\n}\n.quill-error .ql-toolbar.ql-snow {\n  border-color: #ff4848;\n  border-top-width: 2px;\n}\n.ql-toolbar.ql-snow {\n  border-left-width: 0px;\n  border-right-width: 0px;\n}\n.tag-input span.chip {\n  font-size: 0.9em;\n}\n.tag-input span.v-chip {\n  font-size: 0.9em;\n  padding-left: 7px;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
