<template>
  <m-container class="question-answer-form px-7 py-4">
    <div class="content-header mt-3 mb-1">
      {{ !$language ? "Answers" : "উত্তর" }}
    </div>

    <v-form ref="answerDetailsForm" v-model="answerFormOk">
      <v-row>
        <v-col cols="6">
          <v-text-field
            :value="formData.word_limit"
            :label="!$language ? 'Character Limit' : 'ক্যারেক্টার লিমিট'"
            :placeholder="
              !$language ? 'Enter character limit' : 'ক্যারেক্টার লিমিট লিখুন'
            "
            :readonly="readonly"
            type="number"
            :rules="rules.word_limit"
            @change="v => (formData.word_limit = v)"
            @keydown.enter.prevent
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </m-container>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "SingleQuestionFormAnswersDesc",
  mixins: [FieldValidations],
  model: {
    prop: "answerPreference"
  },
  props: {
    answerPreference: {
      type: Object,
      required: true
    },
    isValid: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        ...this.answerPreference
      },
      rules: {
        word_limit: [this.required, v => this.betweenValue(v, 50, 8000)]
      },
      answerFormOk: this.isValid
    };
  },
  computed: {},
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
        this.$emit("update:is-valid", this.answerFormOk);
      }
    }
  },
  mounted() {
    if (this.answerPreference.word_limit)
      this.$emit("update:is-valid", this.answerFormOk);
  },
  methods: {
    async validate() {
      await this.$refs.answerDetailsForm.validate();
    }
  }
};
</script>

<style></style>
